<template>
  <a
    :href="href"
    :download="download"
    :class="[buttonClass, transitionClass]"
    v-ripple
    :target="target"
    :disabled="disabled"
  >
    <span
      v-if="loading"
      :class="iconClass"
      class="p-button-loading-icon pi pi-spinner pi-spin p-p-2"
    />
    <icon v-else-if="icon" :class="iconClass" :data="icon" :fill="fillColor" :color="iconColor" />
    <span v-if="label || hasSlotContent" class="p-button-label" :class="{ 'p-text-nowrap': truncate }">
      <slot>{{ label }}</slot>
    </span>
  </a>
</template>

<script>
import Ripple from "primevue/ripple";

export default {
  props: {
    label: {
      type: String,
    },
    icon: {
      type: [String, Object],
    },
    iconPos: {
      type: String,
      default: "left",
    },
    iconColor: {
      type: String,
      default: "var(--primary-color)",
    },
    fillColor: {
      type: Boolean,
      default: false,
    },
    badge: {
      type: String,
    },
    badgeClass: {
      type: String,
      default: null,
    },
    href: {
      type: String,
    },
    download: {
      type: [String, Boolean],
      default: false,
    },
    target: {
      type: String,
      default: "_self",
    },
    truncate: {
      type: Boolean,
      default: true,
    },
    transition: {
      type: Boolean,
      default: false,
    },
    loading: Boolean,
  },
  computed: {
    hasSlotContent() {
      return this.$slots.default && this.$slots.default()[0].children;
    },
    buttonClass() {
      return {
        "p-button p-component": true,
        "p-button-icon-only": this.icon && !(this.label || this.hasSlotContent),
        "p-button-vertical": (this.iconPos === "top" || this.iconPos === "bottom") && this.label,
        "p-disabled": this.$attrs.disabled || this.loading,
        "p-button-loading": this.loading,
      };
    },
    iconClass() {
      return [
        "p-button-icon pi",
        {
          "p-button-icon-left": this.iconPos === "left" && (this.label || this.hasSlotContent),
          "p-button-icon-right": this.iconPos === "right" && (this.label || this.hasSlotContent),
          "p-button-icon-top": this.iconPos === "top" && (this.label || this.hasSlotContent),
          "p-button-icon-bottom": this.iconPos === "bottom" && (this.label || this.hasSlotContent),
        },
      ];
    },
    transitionClass() {
      return {
        "transition-effect": this.transition,
      };
    },
    badgeStyleClass() {
      return [
        "p-badge p-component",
        this.badgeClass,
        {
          "p-badge-no-gutter": this.badge && String(this.badge).length === 1,
        },
      ];
    },
    disabled() {
      return this.$attrs.disabled || this.loading;
    },
  },
  directives: {
    ripple: Ripple,
  },
};
</script>
<style scoped lang="scss">
.p-button {
  max-width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  .p-button-label {
    display: inline-flex;
    align-items: center;
    width: 90%;
  }
  .svg-icon,
  .p-button-loading-icon {
    flex: 0 0 auto;
    height: 1.889em;
    width: 1.889em;
  }
}
.transition-effect {
  &:hover {
    background: var(--primary-color-dark);
    color: var(--primary-color-text);
    border-color: var(--primary-color-dark);
  }
}
</style>
